<template>
  <div class="project-container">

    <div class="project-heading">
      <div class="project-title">
        Projektliste
        <router-link to="/projects/project"><img src="/images/add.png"/></router-link>
      </div>
      <div class="filters">
        <div class="project-filter">
          <input v-model="activeFilter.name" class="search-input" placeholder="Suche" type="text"
                 @input="filterByName()">
        </div>
        <div class="project-filter">
          <Multiselect
              v-model="userFilter.value"
              v-bind="userFilter"
              @deselect="filterByUser()"
              @select="filterByUser()"
          >
            <template v-slot:multiplelabel="{ values }">
              <div class="multiselect-multiple-label">
                {{ values.length }} ausgewält
              </div>
            </template>
          </Multiselect>
        </div>
        <div class="project-filter">
          <Multiselect
              v-model="statusFilter.value"
              v-bind="statusFilter"
              @deselect="filterByStatus()"
              @select="filterByStatus()"
          >
            <template v-slot:multiplelabel="{ values }">
              <div class="multiselect-multiple-label">
                {{ values.length }} ausgewält
              </div>
            </template>

            <template v-slot:option="{ option }">
              <img :src="option.icon" class="character-option-icon"> {{ option.name }}
            </template>
          </Multiselect>
        </div>
      </div>
    </div>

    <div class="project-datatable">
      <div class="project-table">
        <div class="table-head">
          <div class="table-row">
            <div v-for="field in tableHead" :key="field" class="table-column">
              <div v-if="field.sortable" class="column-sortable" @click="sort(field.name)">
                <div class="column-sortable-icons">
                  <img v-if="currentSort.field !== field.name" src="/images/arrows.png"/>
                  <img v-if="currentSort.field === field.name && currentSort.direction === 'asc'"
                       src="/images/arrows_asc.png"/>
                  <img v-if="currentSort.field === field.name && currentSort.direction === 'desc'"
                       src="/images/arrows_desc.png"/>
                </div>
                <div class="column-title">{{ field.text }}</div>
              </div>
              <div v-else class="column">
                <div class="column-title">{{ field.text }}</div>
              </div>
            </div>
          </div>
        </div>

        <div class="table-body">
          <div v-for="project in projects" :key="project" class="table-row">
            <div class="table-column name">
              <div class="tooltip" v-if="project.missing_objects === true">
                <img alt="" src="/images/missing_objects.png" class="table-column-image">
                <div class="top">
                  <p>Verknüpfungen fehlen</p>
                  <i></i>
                </div>
              </div>
              <div>
                <b>{{ project.name }}</b> <br/> {{ project.zipcode }} {{ project.city }},
                {{ project.country }}
              </div>
            </div>
            <div class="table-column user">
              <div v-if="project.status > 2 && project.status !== 8" class="assigned-user">
                <img alt="" src="/images/user2.png">
                <span>{{ selectedUser(project.user) }}</span></div>
              <div v-if="project._id !== currentProjectId && (project.status < 3 || project.status === 8)"
                   class="edit-password">
                <img src="/images/edit.svg" @click="applyUser(project)"/> {{ selectedUser(project.user) }}
              </div>
              <div v-if="project._id === currentProjectId && (project.status < 3 || project.status === 8)"
                   class="save-password">
                <img src="/images/cancel_s.png" style="margin-right: 5px;" @click="cancelSetUserToProject()"/>
                <img src="/images/ok_password.png" @click="setUserToProject(project)"/>
                <select v-model="selectedUserId">
                  <option :selected="project.user === ''" value="">{{
                      unassigned
                    }}
                  </option>
                  <option v-for="user in activeUsers" v-bind:key="user._id" :selected="user._id === project.user"
                          v-bind:value="user._id">
                    {{ user.name }}
                  </option>
                </select>
              </div>
            </div>
            <div class="table-column status">
              <div v-if="project.status === 1"><img src="/images/create.png"/> <span>Erstellt</span></div>
              <div v-if="project.status === 2"><img src="/images/new.png"/> <span>Neu</span></div>
              <div v-if="project.status === 3"><img src="/images/in_work.png"/> <span>In Arbeit</span></div>
              <div v-if="project.status === 4"><img src="/images/done.png"/> <span>Fertig</span></div>
              <div v-if="project.status === 5"><img src="/images/upload.png"/> <span>Hochgeladen</span></div>
              <div v-if="project.status === 6"><img src="/images/export.png"/> <span>Exportiert</span></div>
              <div v-if="project.status === 7"><img src="/images/disabled.png"/> <span>Deaktiviert</span></div>
              <div v-if="project.status === 8"><img src='/images/icon_status_20_20/reassigned.png'
                                                    class="image-reassigned"/> <span>Neu zuweisen</span></div>
            </div>
            <div class="table-column date">{{ formatDate(project.created_at) }}</div>
            <div class="table-column actions">
              <div v-if="project.status > 2 && project.status < 7 || project.status === 8" class="tooltip">
                <img alt="Exportieren" src="/images/button_export.png" @click="exportData(project)"/>
                <div class="top">
                  <p>Exportieren</p>
                  <i></i>
                </div>
              </div>
              <div v-else>
                <img class="no-hover" src="/images/button_export_disabled.png"/>
              </div>
              <div v-if="project.status === 5 || project.status === 6" class="tooltip">
                <img src="/images/reopen.png" alt="Reopen" class="image-reopen" @click="reassignProject(project)"/>
                <div class="top">
                  <p>Re-Open</p>
                  <i></i>
                </div>
              </div>
              <div v-else>
                <img class="no-hover image-reopen" src="/images/reOpen_disabled.png"/>
              </div>
              <div v-if="project.status < 7" class="tooltip">
                <img alt="Deaktivieren" src="/images/button_no_ban.png" @click="disableProject(project)"/>
                <div class="top">
                  <p>Deaktivieren</p>
                  <i></i>
                </div>
              </div>
              <div v-else>
                <img class="no-hover disabled-icon" src="/images/disabled_icon.png"/>
              </div>
              <div v-if="project.status === 1" class="tooltip">
                <img alt="Löschen" src="/images/button_delete.png" @click="removeProject(project)"/>
                <div class="top">
                  <p>Löschen</p>
                  <i></i>
                </div>
              </div>
              <div v-else>
                <img class="no-hover" src="/images/button_delete_disabled.png"/>
              </div>
              <div class="tooltip">
                <img alt="Details" src="/images/Details.png" style="height: 24px;"
                     @click="this.$router.push(`/projects/project/${project._id}`)"/>
                <div class="top">
                  <p>
                    Details
                  </p>
                  <i></i>
                </div>
              </div>
              <div v-if="(project.status === 5 || project.status === 6) && !project.uploaded_to_dam &&
              (project.dam_status !== 1 && project.dam_status !== 2 && project.dam_status !== 3 && project.dam_status !== 4)"
                   class="tooltip dam">
                <img alt="Upload to DAM" src="/images/icon_upload.png" @click="uploadData(project)"/>
                <div class="top">
                  <p>Upload to DAM</p>
                  <i></i>
                </div>
              </div>
              <div v-else-if="!project.uploaded_to_dam && (project.dam_status !== 1 && project.dam_status !== 2 &&
              project.dam_status !== 3 && project.dam_status !== 4)"
                   class="tooltip dam">
                <img alt="Upload to DAM" src="/images/icon_upload_dis_high.png"/>
                <div class="top">
                  <p>Upload to DAM</p>
                  <i></i>
                </div>
              </div>
              <div v-if="project.dam_status === 1" class="tooltip dam">
                <img alt="In Queue" src="/images/icon_queue.png"/>
                <div class="top">
                  <p>In Queue</p>
                  <i></i>
                </div>
              </div>
              <div v-if="project.dam_status === 2" class="tooltip dam">
                <img alt="Uploading" src="/images/icon_loading.png"/>
                <div class="top">
                  <p>Uploading</p>
                  <i></i>
                </div>
              </div>
              <div v-if="project.dam_status === 3" class="tooltip dam">
                <img alt="Uploaded" src="/images/icon_loaded.png"/>
                <div class="top">
                  <p>Uploaded</p>
                  <i></i>
                </div>
              </div>
              <div v-if="project.dam_status === 4" class="tooltip dam">
                <img alt="Error" src="/images/icon_error.png" @click="updateDamStatus(project)"/>
                <div class="top">
                  <p>Error</p>
                  <i></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="pages > 1" class="table-paging">
      <div v-if="selectedPage > 3" class="first-page" @click="changePage(1)">&lt;&lt;</div>
      <div v-if="selectedPage - 1 > 0" class="prev-page" @click="changePage(selectedPage - 1)">&lt;</div>
      <div v-if="selectedPage > 2" class="page" @click="changePage(selectedPage - 2)">{{ selectedPage - 2 }}</div>
      <div v-if="selectedPage > 1" class="page" @click="changePage(selectedPage - 1)">{{ selectedPage - 1 }}</div>
      <div class="page active">{{ selectedPage }}</div>
      <div v-if="selectedPage + 1 <= pages" class="page" @click="changePage(selectedPage + 1)">{{
          selectedPage + 1
        }}
      </div>
      <div v-if="selectedPage + 2 <= pages" class="page" @click="changePage(selectedPage + 2)">{{
          selectedPage + 2
        }}
      </div>
      <div v-if="selectedPage + 1 <= pages" class="next-page" @click="changePage(selectedPage + 1)">&gt;</div>
      <div v-if="pages > 3" class="last-page" @click="changePage(pages)">&gt;&gt;</div>
    </div>
  </div>
</template>

<script>
import * as _ from 'lodash';
import Multiselect from '@vueform/multiselect'
import user from "@/store/modules/user";
import {mapGetters, mapMutations} from 'vuex';

export default {
  name: 'ProjectTable',
  components: {
    Multiselect
  },
  data() {
    return {
      columns: [
        {name: 'name', text: 'Projekt', sortable: true},
        {name: 'user', text: 'Nutzer', sortable: true},
        {name: 'status', text: 'Status', sortable: true},
        {name: 'created_at', text: 'Letzte Statusänderung', sortable: true},
        {name: 'actions', text: 'Aktionen'}
      ],
      selectedUserId: '',
      currentProjectId: '',
      users: [],
      activeUsers: [],
      projectsData: [],
      entries: [],
      filteredEntries: [],
      currentPage: 1,
      allPages: 1,
      perPage: 15,
      currentSort: {
        field: '',
        direction: ''
      },
      unassigned: 'Nicht zugeordnet',
      activeFilter: {
        status: [],
        user: [],
        name: ""
      },
      statusFilter: {
        mode: 'multiple',
        value: null,
        placeholder: 'Status Projekt',
        options: [
          {value: '1', name: 'Erstellt', icon: '/images/icon_status_20_20/create.png'},
          {value: '2', name: 'Neu', icon: '/images/icon_status_20_20/new.png'},
          {value: '3', name: 'In Arbeit', icon: '/images/icon_status_20_20/in_work.png'},
          {value: '4', name: 'Fertig', icon: '/images/icon_status_20_20/done.png'},
          {value: '5', name: 'Hochgeladen', icon: '/images/icon_status_20_20/upload.png'},
          {value: '6', name: 'Exportiert', icon: '/images/icon_status_20_20/export.png'},
          {value: '7', name: 'Deaktiviert', icon: '/images/icon_status_20_20/disabled.png'},
          {value: '8', name: 'Neu zuweisen', icon: '/images/icon_status_20_20/reassigned.png'}
        ],
        maxHeight: 300
      },
      userFilter: {
        mode: 'multiple',
        value: null,
        placeholder: 'Nutzer',
        options: [],
        maxHeight: 300
      },
    }
  },
  computed: {
    ...mapGetters(['getFilteredData', 'getSortedData']),
    tableHead() {
      return this.columns;
    },
    projects() {
      return this.filteredEntries;
    },
    pages() {
      return this.allPages;
    },
    selectedPage() {
      return this.currentPage;
    }
  },
  created() {
    this.activeFilter = this.getFilteredData;
    this.currentSort = this.getSortedData;
    this.getData();
  },
  methods: {
    ...mapMutations(['setFilteredData', 'setSortedData']),
    getData() {
      this.$store.dispatch('getActiveUsers')
          .then((response) => {
            this.users = response.data.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : -1);
            for (const user of this.users) {
              this.userFilter.options.push({value: user._id, label: user.name});
            }
          })
          .catch((error) => {
            console.log(error);
          });
      this.$store.dispatch('getProjects')
          .then((response) => {
            this.entries = this.projectsData = response.data;
            this.allPages = Math.ceil(response.data.length / this.perPage);
            this.updateFilteredProjects();
            this.userFilter.value = this.activeFilter.user;
            this.statusFilter.value = this.activeFilter.status;
          })
          .catch((error) => {
            console.log(error);
          });
    },
    changeProjectStatus(project, isDeleted = false) {
      const formData = {
        _id: project._id,
        name: project.name,
        user: project.user,
        city: project.city,
        country: project.country,
        zipcode: project.zipcode,
        status: project.status,
        is_deleted: isDeleted
      };
      this.$store.dispatch('saveProject', formData).then().catch((error) => {
        console.log(error);
      });
    },
    updateDamStatus(project) {
      const formData = {
        _id: project._id,
        dam_status: 0
      };
      this.$store.dispatch('updateProjectStatus', formData).then().catch((error) => {
        console.log(error);
      });
    },
    applyUser(project) {
      this.currentProjectId = project._id;
      this.editProject = true;
      this.selectedUserId = project.user;
      this.$store.dispatch('getActiveUsers').then((response) => {
        this.activeUsers = response.data.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : -1);
      })
    },
    setUserToProject(project) {
      let status = 2;
      if (this.selectedUserId === '') {
        this.selectedUserId = null;
        status = 1;
      }

      if (project.status === 8) {
        status = 8;
      }

      const formData = {
        _id: project._id,
        user: this.selectedUserId,
        status: status,
      };
      this.$store.dispatch('updateProjectStatus', formData).then(() => {
        this.entries.find(project => project._id === formData._id).user = formData.user;
        this.entries.find(project => project._id === formData._id).status = formData.status;
        this.updateFilteredProjects();
        this.selectedUserId = '';
        this.currentProjectId = '';
      }).catch((error) => {
        console.log(error);
      });
    },
    cancelSetUserToProject() {
      this.selectedUserId = '';
      this.currentProjectId = '';
    },
    selectedUser(userId) {
      if (userId === null || userId === "" || userId === undefined) {
        return this.unassigned;
      } else {
        const user = this.users.find(user => user._id === userId);
        if (user) {
          return user.name;
        } else {
          return this.unassigned;
        }
      }
    },
    formatDate(date) {
      return new Date(date).toLocaleDateString();
    },
    reassignProject(project) {
      project.status = 8;
      project.is_deleted = false;

      const formData = {
        _id: project._id,
        name: project.name,
        user: project.user,
        city: project.city,
        country: project.country,
        zipcode: project.zipcode,
        status: project.status,
        is_deleted: project.is_deleted
      };

      this.$store.dispatch('updateProjectStatus', formData).then(() => {
        this.entries.find(p => p._id === project._id).status = project.status;
        this.updateFilteredProjects();
      })
    },
    exportData(project) {
      if (project.status === 5) {
        project.status = 6;

        const formData = {
          _id: project._id,
          status: project.status,
        };

        console.log(formData)
        this.$store.dispatch('updateProjectStatus', formData).then(() => {
          this.entries.find(p => p._id === project._id).status = project.status;
          this.updateFilteredProjects();
        })
      }
      this.$store.dispatch('getReport', project._id).then((response) => {
        const pom = document.createElement('a');
        pom.setAttribute('href', 'data:text/csv;charset=utf-8,' + encodeURIComponent(response.data));
        pom.setAttribute('download', `${project.name}` + '.csv');

        if (document.createEvent) {
          const event = document.createEvent('MouseEvents');
          event.initEvent('click', true, true);
          pom.dispatchEvent(event);
        } else {
          pom.click();
        }
      }).catch((error) => {
        console.log(error.response.data.error.message)
      });
    },
    uploadData(project) {
      this.$store.dispatch('uploadData', project._id).then((response) => {
        console.log(response);
      }).catch((error) => {
        console.log(error.response.data.error.message)
      });
    },
    disableProject(project) {
      const r = confirm("Wollen Sie das Projekt wirklich deaktivieren ? Es gehen Daten verloren und Sie können diese Aktion nicht rückgängig machen.");
      if (r === true) {
        project.status = 7;
        const formData = {
          _id: project._id,
          status: project.status,
        };

        this.$store.dispatch('updateProjectStatus', formData).then(() => {
          this.entries.find(p => p._id === project._id).status = project.status;
          this.updateFilteredProjects();
        })
      }
    },
    removeProject(project) {
      if (project.status === 1) {
        this.changeProjectStatus(project, true);
        this.entries.splice(this.entries.findIndex((p) => {
          return p._id === project._id;
        }), 1);
        this.updateFilteredProjects();
      }
    },
    sort(name) {
      let direction = 'asc';
      if (name === this.currentSort.field && this.currentSort.direction === 'asc') {
        direction = 'desc';
      }

      this.currentSort = {
        field: name,
        direction: direction
      };
      this.setSortedData(this.currentSort);
      // move to first page
      this.currentPage = 1;
      this.updateFilteredProjects();
    },
    changePage(page) {
      this.currentPage = page;
      this.updateFilteredProjects();
    },
    filterByStatus() {
      const statuses = [];
      this.statusFilter.value.forEach(
          (status) => {
            statuses.push(parseInt(status));
          }
      );
      this.activeFilter.status = statuses;
      this.filter();
    },
    filterByUser() {
      const users = [];
      this.userFilter.value.forEach(
          (id) => {
            users.push(id);
          }
      );
      this.activeFilter.user = users;
      this.filter();
    },
    filterByName() {
      this.filter();
    },
    filter() {
      this.currentPage = 1;
      this.updateFilteredProjects();
    },
    updateFilteredProjects() {
      this.setFilteredData(this.activeFilter);
      if (this.activeFilter.status.length > 0) {
        this.entries = _.filter(this.entries, p => {
          return this.activeFilter.status.includes(p.status);
        });
      }
      if (this.activeFilter.user.length > 0) {
        this.entries = _.filter(this.entries, p => {
          return this.activeFilter.user.includes(p.user);
        });
      }
      if (this.activeFilter.name.length > 0) {
        this.entries = _.filter(this.entries, p => {
          return p.name.toLowerCase().includes(this.activeFilter.name.toLowerCase()) || p.zipcode.toLowerCase().includes(this.activeFilter.name.toLowerCase()) || p.city.toLowerCase().includes(this.activeFilter.name.toLowerCase()) || p.country.toLowerCase().includes(this.activeFilter.name.toLowerCase());
        });
      }

      this.allPages = Math.ceil(this.entries.length / this.perPage);

      const limit = this.perPage;
      const offset = (this.currentPage - 1) * this.perPage;
      if (this.currentSort.field.length > 0 && this.currentSort.direction.length > 0) {
        if (this.currentSort.field === 'status' || this.currentSort.field === 'user') {
          this.entries = _.orderBy(this.entries, (a) => a[this.currentSort.field], [this.currentSort.direction]);
        } else if (this.currentSort.field === 'number' || this.currentSort.field === 'sales_representative') {
          this.entries = _.orderBy(this.entries, (a) => a[this.currentSort.field], [this.currentSort.direction]);
        } else {
          this.entries = _.orderBy(this.entries, (a) => a[this.currentSort.field].toLowerCase(), [this.currentSort.direction]);
        }
      }

      this.filteredEntries = this.entries.slice(offset, limit + offset);
      this.entries = this.projectsData;
    },

  }
}
</script>

<style lang="scss">
.tooltip {
  display: inline-block;
  position: relative;
}

.tooltip .top {
  min-width: 100px;
  top: -20px;
  left: 50%;
  transform: translate(-50%, -100%);
  padding: 10px 20px;
  color: #022259;
  background-color: #ffffff;
  font-weight: normal;
  font-size: 13px;
  border-radius: 8px;
  position: absolute;
  z-index: 99999999;
  box-sizing: border-box;
  border: 1px solid #022259;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.5);
  display: none;
}

.top {
  text-align: center;
}

.tooltip:hover .top {
  display: block;
}

.tooltip .top i {
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -12px;
  width: 24px;
  height: 12px;
  overflow: hidden;
}

.tooltip .top i::after {
  content: '';
  position: absolute;
  width: 12px;
  height: 12px;
  left: 50%;
  transform: translate(-50%, -50%) rotate(45deg);
  background-color: #ffffff;
  border: 1px solid #022259;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.5);
}

img {
  &:hover {
    cursor: pointer;
  }

  &.no-hover:hover {
    cursor: default;
  }
}

.disabled-icon {
  width: 17px;
  height: 24px;
}

.image-reassigned {
  width: 30px;
  height: 30px;
}

.image-reopen {
  width: 22px;
  height: 23px;
}

.character-option-icon {
  width: 20px;
  height: 20px;
}

.project-container {
  font-family: 'Roboto', sans-serif;
  padding: 50px;

  .project-heading {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;

    .project-title {
      width: 220px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      font-weight: 500;
      font-size: 36px;
      color: #022259;
      text-align: left;

      img {
        margin-bottom: 3px;
      }
    }

    .filters {
      width: 755px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      flex-wrap: wrap;

      .project-filter {
        width: 235px;
      }
    }

    .search-input {
      font-size: 18px;
      color: #022259;
      background: #FFFFFF;
      border: 2px solid #022259;
      border-radius: 13px;
      padding: 7.5px 15px;
      width: calc(100% - 34px);
    }

    .multiselect {
      font-size: 18px;
      color: #022259;
      background: #FFFFFF;
      border: 2px solid #022259;
      border-radius: 13px;
      cursor: pointer;

      .multiselect-caret {
        color: #022259;
        background-color: #022259;
      }
    }

    .multiselect-placeholder {
      color: #022259;
    }

    .multiselect-clear {
      display: none;
    }

    .multiselect-option.is-selected {
      background: #022259;
    }

    .multiselect.is-active {
      box-shadow: none;
    }

    .multiselect-option {
      &:hover {
        cursor: pointer;
      }

      img {
        padding-right: 10px;
      }
    }
  }

  .project-table {
    margin: 35px 0;

    .table-head, .table-body {
      width: 100%;

      .table-row {
        display: grid;
        grid-template-columns: 30% 20% 15% 20% 15%;
        align-items: center;
      }
    }

    .table-head {
      font-weight: 500;
      font-size: 18px;
      color: #022259;
      text-align: left;
      border: 2px solid #EFF9FF;

      .table-row {

        .table-column {

          .column-sortable {
            display: grid;
            grid-template-columns: 20px auto;
            align-self: center;
            align-items: center;
            padding: 10px;

            &:hover {
              cursor: pointer;
            }

            .column-sortable-icons {
              img {
                position: relative;
                top: -2px;
              }
            }
          }

          .column {
            padding: 10px;
          }
        }
      }
    }

    .table-body {
      font-size: 18px;
      font-weight: 400;
      color: #000000;

      .table-row {
        min-height: 60px;

        &:nth-child(odd) {
          background: #EFF9FF;
        }

        .table-column {
          padding: 0 10px;
          line-height: 22px;

          .table-column-image {
            width: 30px;
            height: 30px;
            margin-right: 10px;
          }

          b {
            font-size: 18px;
            font-weight: 500;
          }

          &.name {
            display: flex;
            flex-direction: row;
            align-items: center;
          }

          &.user {
            .assigned-user {
              span {
                padding-left: 10px;
                position: relative;
                top: 3px;
              }
            }

            .edit-password {
              img {
                padding-right: 10px;

                &:hover {
                  cursor: pointer;
                }
              }
            }

            .save-password {
              img {
                position: relative;
                top: -2px;

                &:hover {
                  cursor: pointer;
                }
              }

              select {
                max-width: 80%;
                height: 30px;
                background: #FFFFFF;
                border: 1px solid #022259;
                box-sizing: border-box;
                border-radius: 11px;
                padding-left: 10px;
                margin-left: 5px;
              }
            }
          }

          &.status {
            div span {
              position: relative;
              top: 1px;
              padding-left: 10px;
            }
          }

          .image-reopen {
            width: 18px;
            height: 24px;
            padding-right: 4px;
          }

          &.actions {
            display: grid;
            grid-template-columns: 20px 20px 20px 20px 24px 30px 30px;
            grid-column-gap: 5px;
            align-items: center;

            .dam {
              img {
                width: 30px;
              }
            }

            .round {
              width: 20px;
              height: 20px;
              border-radius: 20px;
              border: 2px solid #022259;

              &.queue {
                background: #2C69D8;
              }

              &.processing {
                background: #FF9544;
              }

              &.uploaded {
                background: #0CAE00;
              }

              &.failed {
                background: #EB2F2F;
              }
            }

            .tooltip {
              display: inline-block;
              position: relative;
            }

            .tooltip .top {
              min-width: 100px;
              top: -20px;
              left: 50%;
              transform: translate(-50%, -100%);
              padding: 10px 20px;
              color: #022259;
              background-color: #ffffff;
              font-weight: normal;
              font-size: 13px;
              border-radius: 8px;
              position: absolute;
              z-index: 99999999;
              box-sizing: border-box;
              border: 1px solid #022259;
              box-shadow: 0 1px 8px rgba(0, 0, 0, 0.5);
              display: none;
            }

            .tooltip:hover .top {
              display: block;
            }

            .tooltip .top i {
              position: absolute;
              top: 100%;
              left: 50%;
              margin-left: -12px;
              width: 24px;
              height: 12px;
              overflow: hidden;
            }

            .top {
              text-align: center;
            }

            .tooltip .top i::after {
              content: '';
              position: absolute;
              width: 12px;
              height: 12px;
              left: 50%;
              transform: translate(-50%, -50%) rotate(45deg);
              background-color: #ffffff;
              border: 1px solid #022259;
              box-shadow: 0 1px 8px rgba(0, 0, 0, 0.5);
            }

            img {
              &:hover {
                cursor: pointer;
              }

              &.no-hover:hover {
                cursor: default;
              }
            }
          }
        }
      }
    }
  }
}

.table-paging {
  display: grid;
  grid-template-columns: auto auto auto auto auto auto auto auto auto;
  justify-content: right;
  align-items: center;
  margin-top: 25px;

  .first-page, .prev-page, .next-page, .last-page {
    background: #022259;
    border-radius: 15px;
    padding: 7px;
    color: #ffffff;
    width: 15px;
    text-align: center;
    margin: 0 7px;

    &:hover {
      cursor: pointer;
    }
  }

  .page {
    width: 15px;
    color: #022259;
    margin: 0 7px;

    &:hover {
      cursor: pointer;
    }

    &.active {
      font-weight: bold;

      &:hover {
        cursor: default;
      }
    }
  }
}

.project-datatable {
  width: 100%;
}

@media screen and (max-width: 1400px) {
  .project-datatable {
    overflow-x: auto;
  }

  .project-table {
    width: 1500px;
  }
}
</style>
<style src="@vueform/multiselect/themes/default.css"></style>
