
import { defineComponent } from 'vue';
import ProjectTable from '@/components/ProjectTable.vue';

export default defineComponent({
                                 name: 'Projects',
                                 components: {
                                   'project-table': ProjectTable
                                 }
                               });
